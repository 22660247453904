import { Component, Input } from "@angular/core";
import { ChannelTypes } from "../../channel";
import { Source } from "src/app/models/shared";

@Component({
    selector: "app-all-channel-sources",
    templateUrl: "./all-channel-sources.component.html"
})
export class AllChannelSourcesComponent {
    @Input() channel: ChannelTypes;
    @Input() source: Source;
    @Input() autoRows? = true;
}
