<app-table-list
    tableName="ad-marks"
    [displayTableName]="'AD_MARKERS' | translate"
    [data]="(adMarks$ | async) || []"
    [(tableSchema)]="tableColumnsSchema"
    (currentPageInfo)="currentPageInfo($event)"
    [showSelectionCheckbox]="false"
    [showReport]="false"
    [showPagination]="true"
    [showFilter]="false"
    [showColumnsSelection]="false"
    [selectable]="false"
    [hoverable]="false"
    [autoRows]="false"
>
</app-table-list>
