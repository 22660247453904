<content-loading-animation *ngIf="!tagsLoaded"></content-loading-animation>
<form id="form" #form="ngForm" *ngIf="tagsLoaded">
    <div zmid="title" class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1>{{ "CREATE_NEW_CHANNEL" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <!-- Type -->
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <h4>
                        {{ "SELECT_CHANNEL_TYPE" | translate }}
                    </h4>
                    <div class="card mb-3">
                        <ul class="list-group list-group-flush type-list">
                            <li zmid="transcoded-type" class="list-group-item" (click)="goto('transcode')" [class.disabled]="adaptiveResourceTags.length === 0">
                                <h4>{{ "TRANSCODED" | translate }}</h4>
                                <div *ngIf="adaptiveResourceTags.length > 0">
                                    <p class="card-text">
                                        {{ "TRANSCODE_CHANNEL_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "TARGETS" | translate }}:</strong> {{ "TRANSCODE_CHANNEL_TARGET_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="adaptiveResourceTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                            <li
                                zmid="pass-through-type"
                                class="list-group-item"
                                (click)="goto('pass-through')"
                                [class.disabled]="deliveryResourceTags.length === 0"
                            >
                                <h4>{{ "DELIVERY" | translate }}</h4>
                                <div *ngIf="deliveryResourceTags.length > 0">
                                    <p class="card-text">
                                        {{ "DELIVERY_CHANNEL_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "TARGETS" | translate }}:</strong> {{ "DELIVERY_CHANNEL_TARGET_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="deliveryResourceTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                            <li class="list-group-item" (click)="goto('failover')" [class.disabled]="failoverResourceTags.length === 0">
                                <h4>{{ "FAILOVER_CHANNEL" | translate }}</h4>
                                <div *ngIf="failoverResourceTags.length > 0">
                                    <p class="card-text">
                                        {{ "FAILOVER_CHANNEL_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "TARGETS" | translate }}:</strong> {{ "DELIVERY_CHANNEL_TARGET_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="failoverResourceTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                            <li
                                zmid="mediaconnect-type"
                                class="list-group-item"
                                (click)="goto('mediaconnect')"
                                [class.disabled]="mediaconnectResourceTags.length === 0"
                            >
                                <h4>{{ "AWS_MEDIACONNECT_FLOW" | translate }}</h4>
                                <div *ngIf="mediaconnectResourceTags.length > 0">
                                    <p class="card-text">
                                        {{ "AWS_MEDIACONNECT_FLOW_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "TARGETS" | translate }}:</strong> {{ "AWS_MEDIACONNECT_FLOW_TARGET_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="mediaconnectResourceTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                            <li zmid="medialive-type" class="list-group-item" (click)="goto('medialive')" [class.disabled]="medialiveResourceTags.length === 0">
                                <h4>{{ "AWS_MEDIALIVE_CHANNEL" | translate }}</h4>
                                <div *ngIf="medialiveResourceTags.length > 0">
                                    <p class="card-text">
                                        {{ "AWS_MEDIALIVE_CHANNEL_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "TARGETS" | translate }}:</strong> {{ "AWS_MEDIALIVE_CHANNEL_TARGET_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="medialiveResourceTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                            <ng-container *ngIf="showMoreOptions">
                                <li
                                    zmid="adaptive-type"
                                    class="list-group-item"
                                    (click)="goto('adaptive')"
                                    [class.disabled]="adaptiveResourceTags.length === 0"
                                >
                                    <h4>{{ "ADAPTIVE" | translate }}</h4>
                                    <div *ngIf="adaptiveResourceTags.length > 0">
                                        <p class="card-text">
                                            {{ "ADAPTIVE_CHANNEL_TYPE_DESCRIPTION" | translate }}
                                        </p>
                                        <p class="card-text">
                                            <strong>{{ "TARGETS" | translate }}:</strong> {{ "ADAPTIVE_CHANNEL_TARGET_TYPES" | translate }}
                                        </p>
                                    </div>
                                    <div *ngIf="adaptiveResourceTags.length === 0">
                                        <p class="card-text">
                                            {{ "NO_PERMISSIONS" | translate }}
                                        </p>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    <div class="more-option-btn mb-3 ms-2" (click)="showMoreOptions = !showMoreOptions">
                        {{ showMoreOptions ? ("HIDE_OPTIONS" | translate) : ("MORE_OPTIONS" | translate) }}
                    </div>
                    <hr class="mt-0" />
                    <div class="text-start">
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
