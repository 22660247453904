import { Component, OnInit, OnChanges, Input, SimpleChanges, ComponentRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AdaptiveChannel, DeliveryChannel } from "../../channel";
import { Constants } from "../../../../constants/constants";
import { KeyMap } from "../../../../models/shared";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { TranslateService } from "@ngx-translate/core";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { DecimalPipe } from "@angular/common";
import { ZxNumericColComponent } from "src/app/components/shared/zx-numeric-col/zx-numeric-col.component";
import { ChannelBitratesStatusColumnComponent } from "./channel-bitrates-status-column/channel-bitrates-status-column.component";
import { ChannelBitratesSourceStatusColumnComponent } from "./channel-bitrates-source-status-column/channel-bitrates-source-status-column.component";
import { ChannelBitratesProfileColumnComponent } from "./channel-bitrates-profile-column/channel-bitrates-profile-column.component";
import { Bitrate } from "@zixi/models";
import { ChannelsService } from "../../channels.service";
import { ChannelBitratesSourceColumnComponent } from "./channel-bitrates-source-column/channel-bitrates-source-column.component";

@Component({
    selector: "app-channel-bitrates",
    templateUrl: "./channel-bitrates.component.html"
})
export class ChannelBitratesComponent implements OnInit, OnChanges {
    @Input() channel: AdaptiveChannel;
    @Input() autoRows? = true;

    constants = Constants;

    bitrates = [];
    multiGpu = false;
    currentSortDirection: string;

    private bitratesBS$ = new BehaviorSubject<Bitrate[]>([]);

    tableColumnsSchema: TableSchema<KeyMap<Bitrate>>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Bitrate>>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: row => row.name
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 120,
            visible: true,
            component: ChannelBitratesStatusColumnComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<ChannelBitratesStatusColumnComponent>,
                row: KeyMap<Bitrate>
            ) => {
                const componentInstance = componentRef.instance;
                componentInstance.bitrate = row;
                componentInstance.channel = this.channel;
            }
        },
        {
            header: this.translate.instant("SOURCE_STATUS"),
            columnDef: "source_status",
            width: 120,
            visible: true,
            component: ChannelBitratesSourceStatusColumnComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<ChannelBitratesSourceStatusColumnComponent>,
                row: KeyMap<Bitrate>
            ) => {
                const componentInstance = componentRef.instance;
                componentInstance.bitrate = row;
                componentInstance.channel = this.channel;
            }
        },
        {
            header: this.translate.instant("BITRATE"),
            columnDef: "bitrate",
            width: 80,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Bitrate>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number: this.decimalPipe.transform(row.kbps, "1.0-0")
                        ? this.decimalPipe.transform(row.kbps, "1.0-0")
                        : 0,
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            sortBy: row => row.kbps
        },
        {
            header: this.translate.instant("PROFILE"),
            columnDef: "profile",
            width: 120,
            visible: true,
            component: ChannelBitratesProfileColumnComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<ChannelBitratesProfileColumnComponent>,
                row: KeyMap<Bitrate>
            ) => {
                const componentInstance = componentRef.instance;
                componentInstance.bitrate = row;
            },
            sortBy: row => row.profile.name
        }
        // TODO: did we ever actually have preview URLs here?
        /* {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 40,
            align: "right",
            visible: true,
            hideColumnName: true,
            stickyToLast: true,
            component: ZxTableActionsComponent,
            assignComponentsInputs: assignGenericLinkTextInputsFactory([
                {
                    icon: "play",
                    translateTitle: "PLAY",
                    onClickHandler: (row: Bitrate) => this.cs.openChannelPreview(row),
                    iconSize: "xs",
                    isVisible: true,
                    isTextVisible: true
                }
            ])
        } */
    ];

    tableColumnsSchemaNotTranscoding: TableSchema<KeyMap<Bitrate>>[] = [
        {
            header: this.translate.instant("SOURCE"),
            columnDef: "source",
            width: 160,
            visible: true,
            component: ChannelBitratesSourceColumnComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<ChannelBitratesSourceColumnComponent>,
                row: KeyMap<Bitrate>
            ) => {
                const componentInstance = componentRef.instance;
                componentInstance.bitrate = row;
            },
            sortBy: row => row.source.name
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 120,
            visible: true,
            component: ChannelBitratesStatusColumnComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<ChannelBitratesStatusColumnComponent>,
                row: KeyMap<Bitrate>
            ) => {
                const componentInstance = componentRef.instance;
                componentInstance.bitrate = row;
                componentInstance.channel = this.channel;
            }
        },
        {
            header: this.translate.instant("SOURCE_STATUS"),
            columnDef: "source_status",
            width: 120,
            visible: true,
            component: ChannelBitratesSourceStatusColumnComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<ChannelBitratesSourceStatusColumnComponent>,
                row: KeyMap<Bitrate>
            ) => {
                const componentInstance = componentRef.instance;
                componentInstance.bitrate = row;
                componentInstance.channel = this.channel;
            }
        },
        {
            header: this.translate.instant("BITRATE"),
            columnDef: "bitrate",
            width: 80,
            visible: true,
            align: "right",
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Bitrate>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number: this.decimalPipe.transform(row.kbps, "1.0-0")
                        ? this.decimalPipe.transform(row.kbps, "1.0-0")
                        : 0,
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            sortBy: row => row.kbps
        }
    ];

    tableColumnsSchemaGPU: TableSchema<KeyMap<Bitrate>>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Bitrate>>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: row => row.name
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 120,
            visible: true,
            component: ChannelBitratesStatusColumnComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<ChannelBitratesStatusColumnComponent>,
                row: KeyMap<Bitrate>
            ) => {
                const componentInstance = componentRef.instance;
                componentInstance.bitrate = row;
                componentInstance.channel = this.channel;
            }
        },
        {
            header: this.translate.instant("SOURCE_STATUS"),
            columnDef: "source_status",
            width: 120,
            visible: true,
            component: ChannelBitratesSourceStatusColumnComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<ChannelBitratesSourceStatusColumnComponent>,
                row: KeyMap<Bitrate>
            ) => {
                const componentInstance = componentRef.instance;
                componentInstance.bitrate = row;
                componentInstance.channel = this.channel;
            }
        },
        {
            header: this.translate.instant("BITRATE"),
            columnDef: "bitrate",
            width: 80,
            visible: true,
            align: "right",
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<Bitrate>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number: this.decimalPipe.transform(row.kbps, "1.0-0")
                        ? this.decimalPipe.transform(row.kbps, "1.0-0")
                        : 0,
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            sortBy: row => row.kbps
        },
        {
            header: this.translate.instant("PROFILE"),
            columnDef: "profile",
            width: 120,
            visible: true,
            component: ChannelBitratesProfileColumnComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<ChannelBitratesProfileColumnComponent>,
                row: KeyMap<Bitrate>
            ) => {
                const componentInstance = componentRef.instance;
                componentInstance.bitrate = row;
            },
            sortBy: row => row.profile.name
        },
        {
            header: this.translate.instant("GPU"),
            columnDef: "GPU",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Bitrate>>(
                row => row.gpu_id.toString(),
                row => row.gpu_id.toString(),
                () => true
            ),
            sortBy: row => row.gpu_id
        }
    ];

    constructor(private translate: TranslateService, private decimalPipe: DecimalPipe, private cs: ChannelsService) {}

    ngOnInit() {
        this.bitrates = this.channel.bitrates;
        this.multiGpu = this.bitrates?.length > 0 ? !!this.bitrates.find(b => b.gpu_id != null) : false;
        this.prepTableData();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.channel) {
            // Make sure it's not initial load
            if (changes.channel.previousValue) {
                this.bitrates = this.channel.bitrates;
                this.multiGpu = !!this.bitrates.find(b => b.gpu_id != null);
                this.prepTableData();
            }
        }
    }

    get bitrates$() {
        return this.bitratesBS$.asObservable();
    }

    onSort(s: string) {
        this.currentSortDirection = s;
    }

    private prepTableData() {
        if (this.bitrates) {
            this.bitratesBS$.next(this.bitrates);
        }
    }
}
