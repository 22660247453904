<div class="details-form d-flex flex-wrap flex-row grid-gap-3">
    <div class="flex-1 flex-basis-min maxw-600px">
        <h4 translate>PREVIEW</h4>
        <app-source-thumbnail-from-service
            [source]="source"
            [info]="false"
            [bordered]="true"
            [overlay]="showOverlay"
            [allowLivePlay]="false"
        ></app-source-thumbnail-from-service>
        <div class="d-flex flex-row mt-2">
            <div class="form-group flex-shrink-0 ms-auto">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="show_overlay" name="show_overlay" [(ngModel)]="showOverlay" />
                    <label class="form-check-label" for="show_overlay">{{ "SHOW_OVERLAY" | translate }}</label>
                </div>
            </div>
        </div>
    </div>
</div>
