import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { ChannelsService } from "../channels.service";
import { MediaConnectFlow } from "src/app/models/shared";

@Injectable({
    providedIn: "root"
})
export class MediaConnectFlowChannelListResolverService implements Resolve<MediaConnectFlow[]> {
    constructor(private cs: ChannelsService) {}

    resolve(): Observable<MediaConnectFlow[]> | Observable<never> {
        return new Observable((observe: Subscriber<MediaConnectFlow[]>) => {
            this.cs.getMediaConnectFlows().subscribe((flows: MediaConnectFlow[]) => {
                observe.next(flows);
                observe.complete();
            });
        });
    }
}
