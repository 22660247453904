import { Component, OnDestroy, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";

import { ChannelsService } from "../channels.service";

import { Constants } from "src/app/constants/constants";
import { Menu, Tag } from "src/app/models/shared";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { TitleCasePipe } from "@angular/common";
import { UsersService } from "../../account-management/users/users.service";
import { Subscription } from "rxjs";
import { SharedService } from "src/app/services/shared.service";

@Component({
    selector: "app-channel-form",
    templateUrl: "./channel-form.component.html",
    styleUrls: ["./channel-form.component.scss"],
    providers: [TitleCasePipe]
})
export class ChannelFormComponent implements OnInit, OnDestroy {
    tagsLoaded = false;
    showMoreOptions = false;

    cluster: string = null;
    name: string = null;
    type: string = null;

    adaptiveResourceTags: Tag[] = [];
    deliveryResourceTags: Tag[] = [];
    mediaconnectResourceTags: Tag[] = [];
    medialiveResourceTags: Tag[] = [];
    failoverResourceTags: Tag[] = [];

    private menuSubscription: Subscription;
    menu: Menu;
    constants = Constants;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private cs: ChannelsService,
        private translate: TranslateService,
        private titleService: TitleService,
        private userService: UsersService,
        private location: Location,
        private sharedService: SharedService
    ) {
        // Set Title
        this.titleService.setTitle(this.translate.instant("CHANNEL") + " - New");

        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(async params => {
            if (params.get("type")) this.type = params.get("type");
            if (params.get("cluster")) this.cluster = params.get("cluster");
            if (params.get("name")) this.name = params.get("name");
        });
    }

    async ngOnInit() {
        // resourceTags
        const adaptiveTags = this.sharedService.getResourceTagsByType("adaptive_channel").toPromise();
        const deliveryTags = this.sharedService.getResourceTagsByType("delivery_channel").toPromise();
        const mediaconnectTags = this.sharedService.getResourceTagsByType("mediaconnect_flows").toPromise();
        const medialiveTags = this.sharedService.getResourceTagsByType("medialive_channels").toPromise();
        const failoverTags = this.sharedService.getResourceTagsByType("failover_channel").toPromise();

        await Promise.all([adaptiveTags, deliveryTags, mediaconnectTags, medialiveTags, failoverTags]);

        adaptiveTags.then((tags: Tag[]) => {
            this.adaptiveResourceTags = tags;
        });

        deliveryTags.then((tags: Tag[]) => {
            this.deliveryResourceTags = tags;
        });

        mediaconnectTags.then((tags: Tag[]) => {
            this.mediaconnectResourceTags = tags;
        });

        medialiveTags.then((tags: Tag[]) => {
            this.medialiveResourceTags = tags;
        });

        failoverTags.then((tags: Tag[]) => {
            this.failoverResourceTags = tags;
        });

        this.menuSubscription = this.userService.getMenuPermissions.pipe().subscribe((menu: Menu) => {
            this.menu = menu;
        });

        this.tagsLoaded = true;
    }

    ngOnDestroy(): void {
        this.menuSubscription.unsubscribe();
    }

    goto(type: string) {
        switch (type) {
            case "adaptive":
                if (this.type && this.cluster && this.name)
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.adaptive,
                        "new",
                        this.type,
                        this.cluster,
                        this.name
                    ]);
                else
                    return this.router.navigate([Constants.urls.channels, Constants.urls.channelTypes.adaptive, "new"]);
            case "transcode":
                if (this.type && this.cluster && this.name)
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.transcoded,
                        "new",
                        this.type,
                        this.cluster,
                        this.name
                    ]);
                else
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.transcoded,
                        "new"
                    ]);
            case "pass-through":
                if (this.type && this.cluster && this.name)
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.delivery,
                        "new",
                        this.type,
                        this.cluster,
                        this.name
                    ]);
                else
                    return this.router.navigate([Constants.urls.channels, Constants.urls.channelTypes.delivery, "new"]);
            case "mediaconnect":
                if (this.type && this.cluster && this.name)
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.mediaconnect,
                        "new",
                        this.type,
                        this.cluster,
                        this.name
                    ]);
                else
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.mediaconnect,
                        "new"
                    ]);
            case "medialive":
                if (this.type && this.cluster && this.name)
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.medialive,
                        "new",
                        this.type,
                        this.cluster,
                        this.name
                    ]);
                else
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.medialive,
                        "new"
                    ]);
            case "failover":
                if (this.type && this.cluster && this.name)
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.failover,
                        "new",
                        this.type,
                        this.cluster,
                        this.name
                    ]);
                else
                    return this.router.navigate([Constants.urls.channels, Constants.urls.channelTypes.failover, "new"]);
        }
    }

    cancel() {
        this.location.back();
    }
}
