<!-- AWS Account -->
<div class="form-group">
    <label for="aws_account_id" [ngClass]="{ 'is-invalid': form.submitted && awsAccount.errors }">
        {{ "AWS_ACCOUNT" | translate }}
        <fa-icon icon="asterisk" size="xs" *ngIf="!isEdit"></fa-icon>
        <fa-icon icon="lock" size="sm" [ngbTooltip]="AWSContent" triggers="hover click" [closeDelay]="500"></fa-icon>
        <ng-template #AWSContent>{{ "TOOLTIP.CHANNEL_AWS_ACCOUNT" | translate }}</ng-template>
    </label>
    <ng-select
        id="aws_account_id"
        name="aws_account_id"
        required
        [items]="awsAccounts$ | async"
        [clearable]="false"
        bindValue="id"
        bindLabel="name"
        placeholder="{{ 'SELECT' | translate }} {{ 'AWS_ACCOUNT' | translate }}"
        [(ngModel)]="channel.aws_account_id"
        [disabled]="isEdit"
        (change)="accountChanged()"
        #awsAccount="ngModel"
        [ngClass]="{ 'form-control is-invalid': form.submitted && awsAccount.errors }"
    >
    </ng-select>
    <div *ngIf="awsAccount.invalid" class="invalid-feedback">
        <div *ngIf="awsAccount.errors.required">{{ "AWS_ACCOUNT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
    </div>
</div>

<!-- Region -->
<div class="form-group">
    <label for="region" [ngClass]="{ 'is-invalid': form.submitted && region.errors }">
        {{ "REGION" | translate }}<fa-icon icon="asterisk" size="xs" *ngIf="!isEdit"></fa-icon
        ><fa-icon icon="lock" size="sm" [ngbTooltip]="RegionContent" triggers="hover click" [closeDelay]="500"></fa-icon>
        <ng-template #RegionContent>{{ "TOOLTIP.CHANNEL_REGION_LOCK" | translate }}</ng-template>
    </label>
    <ng-select
        id="region"
        name="region"
        required
        [items]="awsRegions$ | async"
        [clearable]="false"
        placeholder="{{ 'SELECT' | translate }} {{ 'REGION' | translate }}"
        [(ngModel)]="channel.region"
        [disabled]="isEdit || !channel.aws_account_id || awsAccountRegionsLoading || (awsRegions$ | async)?.length === 0"
        (change)="updateAWSRegion()"
        bindLabel="name"
        bindValue="id"
        #region="ngModel"
        [ngClass]="{ 'form-control is-invalid': form.submitted && region.errors }"
        [loading]="awsAccountRegionsLoading"
    >
    </ng-select>
    <div *ngIf="region.invalid" class="invalid-feedback">
        <div *ngIf="region.errors.required">{{ "REGION" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
    </div>
</div>

<!-- Zone -->
<div class="form-group" *ngIf="!import">
    <label for="zone" [ngClass]="{ 'is-invalid': form.submitted && zone.errors }">
        {{ "AWS_ZONE" | translate }}<fa-icon icon="asterisk" size="xs" *ngIf="!isEdit"></fa-icon
        ><fa-icon icon="lock" size="sm" [ngbTooltip]="ZoneContent" triggers="hover click" [closeDelay]="500"></fa-icon>
        <ng-template #ZoneContent>{{ "TOOLTIP.CHANNEL_ZONE_LOCK" | translate }}</ng-template>
    </label>
    <ng-select
        id="zone"
        name="zone"
        required
        [items]="awsZones$ | async"
        [clearable]="false"
        placeholder="{{ 'SELECT' | translate }} {{ 'AWS_ZONE' | translate }}"
        [(ngModel)]="channel.availability_zone"
        [disabled]="isEdit || !channel.aws_account_id || !channel.region || awsAccountZonesLoading || (awsZones$ | async)?.length === 0"
        bindLabel="name"
        bindValue="id"
        #zone="ngModel"
        [ngClass]="{ 'form-control is-invalid': form.submitted && zone.errors }"
        [loading]="awsAccountZonesLoading"
        (change)="avaliabilityZoneSelected()"
    >
    </ng-select>
    <div *ngIf="zone.invalid" class="invalid-feedback">
        <div *ngIf="zone.errors.required">{{ "AWS_ZONE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
    </div>
</div>

<!-- Role ARN Type -->
<fieldset class="form-group" *ngIf="customRoleArn && showRoleArn && !isEdit">
    <legend for="type">
        {{ "IAM_ROLE" | translate }}
    </legend>
    <mat-button-toggle-group name="type" aria-label="type" [(ngModel)]="iamRoleType">
        <mat-button-toggle value="existing">{{ "SELECT_EXISTING" | translate }}</mat-button-toggle>
        <mat-button-toggle value="custom">{{ "SPECIFY_CUSTOM" | translate }}</mat-button-toggle>
    </mat-button-toggle-group>
</fieldset>

<!-- Existing Role ARN -->
<div class="form-group" *ngIf="showRoleArn && iamRoleType === 'existing' && !isEdit">
    <label for="awsIAMRole" [ngClass]="{ 'is-invalid': form.submitted && awsIAMRole.errors }">
        {{ "AWS_ROLE_ARN" | translate }}<fa-icon icon="asterisk" size="xs" *ngIf="!isEdit"></fa-icon
        ><fa-icon icon="lock" size="sm" [ngbTooltip]="ZoneContent" triggers="hover click" [closeDelay]="500"></fa-icon>
        <ng-template #ZoneContent>{{ "TOOLTIP.CHANNEL_ROLE_ARN_LOCK" | translate }}</ng-template>
    </label>
    <ng-select
        id="awsIAMRole"
        name="awsIAMRole"
        required
        [items]="awsIAMRoles"
        [clearable]="false"
        placeholder="{{ 'SELECT' | translate }} {{ 'AWS_ROLE_ARN' | translate }}"
        [(ngModel)]="channel.role_arn"
        bindLabel="name"
        bindValue="arn"
        #awsIAMRole="ngModel"
        [ngClass]="{ 'form-control is-invalid': form.submitted && awsIAMRole.errors }"
        [disabled]="!channel || !channel.aws_account_id || awsIAMRolesLoading || isEdit"
        [loading]="awsIAMRolesLoading"
    >
    </ng-select>
    <div *ngIf="awsIAMRole.invalid" class="invalid-feedback">
        <div *ngIf="awsIAMRole.errors.required">{{ "AWS_ROLE_ARN" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
    </div>
</div>

<!-- Custom Role ARN -->
<div class="form-group" *ngIf="showRoleArn && (iamRoleType === 'custom' || isEdit)">
    <label for="customAwsIAMRole" [ngClass]="{ 'is-invalid': form.submitted && customAwsIAMRole.errors }"
        >{{ "AWS_ROLE_ARN" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
    ></label>
    <input
        type="text"
        id="customAwsIAMRole"
        name="customAwsIAMRole"
        placeholder="e.g. arn:aws:iam::9999999999999:role/roleName"
        class="form-control"
        [ngClass]="{ 'is-invalid': form.submitted && customAwsIAMRole.errors }"
        [(ngModel)]="channel.role_arn"
        [disabled]="isEdit"
        required
        pattern="^arn:aws:iam::\d+:role\/.*$"
        #customAwsIAMRole="ngModel"
    />
    <div *ngIf="customAwsIAMRole.invalid" class="invalid-feedback">
        <div *ngIf="customAwsIAMRole.errors.required">{{ "AWS_ROLE_ARN" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
        <div *ngIf="customAwsIAMRole.errors.pattern">{{ "AWS_ROLE_ARN" | translate }} {{ "INVALID" | translate }}.</div>
    </div>
    <small *ngIf="!isEdit">e.g. arn:aws:iam::9999999999999:role/roleName</small>
</div>
