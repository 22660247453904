<div *ngIf="channel.type === 'adaptive'">
    <!-- Is Transcoding -->
    <div *ngIf="channel.is_transcoding">
        <app-table-list
            [tableName]="'channel-bitrates-istranscoding'"
            [displayTableName]="'BITRATES' | translate"
            [data]="bitrates$ | async"
            [(tableSchema)]="tableColumnsSchema"
            [showSelectionCheckbox]="false"
            (currentSortDirection)="onSort($event)"
            [showReport]="false"
            [showPagination]="true"
            [showFilter]="false"
            [showColumnsSelection]="false"
            [selectable]="false"
            [hoverable]="false"
            [autoRows]="autoRows"
            *ngIf="!multiGpu"
        >
        </app-table-list>
        <app-table-list
            [tableName]="'channel-bitrates-istranscoding-gpu'"
            [displayTableName]="'BITRATES' | translate"
            [data]="bitrates$ | async"
            [(tableSchema)]="tableColumnsSchemaGPU"
            [showSelectionCheckbox]="false"
            (currentSortDirection)="onSort($event)"
            [showReport]="false"
            [showPagination]="true"
            [showFilter]="false"
            [showColumnsSelection]="false"
            [selectable]="false"
            [hoverable]="false"
            [autoRows]="autoRows"
            *ngIf="multiGpu"
        >
        </app-table-list>
    </div>
    <!-- Is Not Transcoding -->
    <div *ngIf="!channel.is_transcoding">
        <app-table-list
            [tableName]="'channel-bitrates'"
            [displayTableName]="'BITRATES' | translate"
            [data]="bitrates$ | async"
            [(tableSchema)]="tableColumnsSchemaNotTranscoding"
            [showSelectionCheckbox]="false"
            (currentSortDirection)="onSort($event)"
            [showReport]="false"
            [showPagination]="true"
            [showFilter]="false"
            [showColumnsSelection]="false"
            [selectable]="false"
            [hoverable]="false"
            [autoRows]="autoRows"
        >
        </app-table-list>
    </div>
</div>
