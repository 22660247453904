import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import {
    AmazonAWS,
    AmazonAWSAvailabilityZone,
    AmazonAWSRegionDetails,
    AmazonAWSRegions,
    AmazonAWSIAMRole
} from "../../configuration/amazon-aws/amazon-aws";
import { AmazonAwsService } from "../../configuration/amazon-aws/amazon-aws.service";
import { MediaLiveChannel } from "../channel";
import { MediaConnectFlow } from "../../../models/shared";
@Component({
    selector: "app-channel-aws-fields",
    templateUrl: "./channel-aws-fields.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ChannelAwsFieldsComponent implements OnInit {
    @Input() channel: MediaLiveChannel | MediaConnectFlow | null;
    @Input() isEdit;
    @Input() form;
    @Input() import = false;
    @Input() hasAny = true;
    @Input() showRoleArn = false;
    @Input() customRoleArn = false;
    @Input() awsIAMRoles: AmazonAWSIAMRole[];
    @Input() awsIAMRolesLoading: boolean;

    @Output() accountChange = new EventEmitter();
    @Output() regionChange = new EventEmitter();
    @Output() regionsLoadingChange = new EventEmitter();
    @Output() regionLoadingChange = new EventEmitter();
    @Output() awsZonesChange = new EventEmitter<AmazonAWSAvailabilityZone[]>();
    @Output() zoneChange = new EventEmitter();

    iamRoleType: "existing" | "custom" = "existing";

    awsAccountRegionsLoading = false;
    awsAccountZonesLoading = false;

    awsAccounts$: Observable<AmazonAWS[]> = this.aas.awsAccounts;
    awsRegions$: Promise<{ id: string; name: string }[]>;
    awsZones$: Promise<AmazonAWSAvailabilityZone[]>;

    constructor(private aas: AmazonAwsService) {}

    ngOnInit() {
        this.aas.refreshAWSAccounts(true);
        this.updateAWSAccountRegions();
    }
    accountChanged() {
        this.accountChange.emit(this.channel.aws_account_id);
        this.updateAWSAccountRegions();
    }

    async updateAWSAccountRegions() {
        if (this.channel.aws_account_id == null) return;

        this.awsAccountRegionsLoading = true;
        this.regionsLoadingChange.emit(this.awsAccountRegionsLoading);

        if (this.channel.region) {
            this.awsAccountZonesLoading = true;
            this.regionLoadingChange.emit(this.awsAccountZonesLoading);
        }

        this.awsRegions$ = this.aas
            .getAWSAccountRegions(this.channel.aws_account_id)
            .then((regions: AmazonAWSRegions) => {
                if (regions) {
                    const awsRegions: { id: string; name: string }[] = [];
                    Object.entries(regions).forEach(([key, value]) => {
                        awsRegions.push({ id: key, name: value });
                    });
                    this.awsAccountRegionsLoading = false;
                    this.regionsLoadingChange.emit(this.awsAccountRegionsLoading);
                    if (this.channel.region) this.updateAWSRegion();
                    return awsRegions;
                }
            });
    }

    async updateAWSRegion() {
        if (this.channel.aws_account_id == null || this.channel.region == null) {
            this.regionChange.emit(null);
            return;
        }

        this.awsAccountZonesLoading = true;
        this.regionLoadingChange.emit(this.awsAccountZonesLoading);
        this.awsZones$ = this.aas
            .getAWSRegionDetails(this.channel.aws_account_id, this.channel.region)
            .then((region: AmazonAWSRegionDetails) => {
                const awsZones: AmazonAWSAvailabilityZone[] = [
                    ...region.zones.map(r => {
                        return { id: r.name, name: r.name };
                    })
                ];

                if (this.hasAny) {
                    awsZones.unshift({ id: "random", name: "Any (default)" });
                }

                if (!awsZones.find(({ id }) => id === this.channel.availability_zone))
                    this.channel.availability_zone = null;

                if (this.channel.availability_zone == null && awsZones.length > 0)
                    this.channel.availability_zone = awsZones[0].id;

                this.regionChange.emit(region);
                this.awsZonesChange.emit(awsZones);
                this.awsAccountZonesLoading = false;
                this.regionLoadingChange.emit(this.awsAccountZonesLoading);
                return awsZones;
            });
    }

    avaliabilityZoneSelected() {
        this.zoneChange.emit(this.channel.availability_zone);
    }
}
