import { ComponentRef } from "@angular/core";
import { Base, LiveEvent } from "@zixi/models";
import { KeyMap } from "src/app/models/shared";
import { FunctionRef, ZxChannelTargetsActionButtonsComponent } from "./zx-channel-targets-action-buttons.component";

type BooleanCallback<T = KeyMap<Base>> = (row: T) => boolean;

export interface TableRowInputs<T = LiveEvent, R = void> {
    canPlayCallBack?: BooleanCallback<T>;
    canDetachCallBack?: BooleanCallback<T>;
    canMuteCallBack?: BooleanCallback<T>;
    canEnableCallBack?: BooleanCallback<T>;
    playRef?: FunctionRef<T>;
    detachRef?: FunctionRef<T>;
    muteRef?: FunctionRef<T>;
    enableRef?: FunctionRef<T>;
}

export const assignTableRowInputsFactory =
    <T = LiveEvent, R = void>(inputs: TableRowInputs<T, R>) =>
    (ComponentRef: ComponentRef<ZxChannelTargetsActionButtonsComponent>, row: KeyMap<T>) => {
        const comp = ComponentRef.instance;
        comp.canPlay = inputs.canPlayCallBack && inputs.canPlayCallBack(row);
        comp.canDetach = inputs.canDetachCallBack && inputs.canDetachCallBack(row);
        comp.canMute = inputs.canMuteCallBack && inputs.canMuteCallBack(row);
        comp.canEnable = inputs.canEnableCallBack && inputs.canEnableCallBack(row);

        comp.playRef = inputs.playRef;
        comp.detachRef = inputs.detachRef;
        comp.muteRef = inputs.muteRef;
        comp.enableRef = inputs.enableRef;
        comp.row = row;
    };
