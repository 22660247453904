import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormControl, Validators, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import _ from "lodash";
import { Subscription } from "rxjs";
import { ModalService } from "src/app/components/shared/modals/modal.service";
import { Constants } from "src/app/constants/constants";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { SharedService } from "src/app/services/shared.service";
import { ClustersService } from "../../clusters/clusters.service";
import { AlertingProfile } from "../../configuration/events-management/events-management";
import { EventsManagementService } from "../../configuration/events-management/events-management.service";
import { DeliveryChannel, FailoverChannel } from "../channel";
import { ChannelsService } from "../channels.service";
import { FailoverRule, Source } from "../../../models/shared";
import { SourcesService } from "../../sources/sources.service";
import { urlBuilder } from "@zixi/shared-utils";
import { createDefaultRuleControl } from "./failover-rules/failover-rules.component";
import { TitleService } from "src/app/services/title.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleCasePipe } from "@angular/common";
import { PidMappingsService } from "../../pid-mappings/pid-mappings.service";
import { FailoverErrorConcealmentData } from "./error-concealment/error-concealment.component";

@Component({
    selector: "app-channel-failover-form",
    templateUrl: "./channel-form.component.html",
    providers: [TitleCasePipe]
})
export class ChannelFormFailoverChannelComponent implements OnInit, OnDestroy {
    saving = false;

    nameControl = new UntypedFormControl({ value: "", disabled: this.saving }, [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    tagsControl = new UntypedFormControl({ value: [], disabled: this.saving }, [Validators.required]);
    disableControl = new UntypedFormControl({ value: false, disabled: this.saving }, []);
    muteControl = new UntypedFormControl({ value: false, disabled: this.saving }, []);
    alertingProfileControl = new UntypedFormControl({ value: [], disabled: this.saving }, []);
    clusterControl = new UntypedFormControl({ value: null, disabled: this.saving }, [Validators.required]);
    failoverSourceControl = new UntypedFormControl({ value: [], disabled: this.saving }, [Validators.maxLength(9)]);
    advanceData = {
        billing_code: "",
        billing_password: "",
        external_id: ""
    };
    errorConcealmentData: FailoverErrorConcealmentData = {
        error_concealment: 0,
        error_concealment_continuous_timeline: 0,
        error_concealment_replace_frames: 0,
        error_concealment_delay_ms: 1000,
        error_concealment_cbr_padding_kbps: null,
        error_concealment_cbr_padding_pcr_interval_ms: 100,
        error_concealment_fix_cc: 0
    };
    priorityUpgradeControl = new FormControl<boolean>({ value: false, disabled: this.saving });
    mergeModeControl = new FormControl<string>({ value: "none", disabled: this.saving });
    latencyControl = new FormControl<number>({ value: 1000, disabled: this.saving }, [
        Validators.required,
        Validators.min(0),
        Validators.pattern(/^\d+$/)
    ]);
    pidProfileControl = new FormControl<number>({ value: null, disabled: this.saving }, []);
    pidMappingProfiles$ = this.pidMappingService.pidMappingProfiles;
    contentAnalysisControl = new UntypedFormControl({ value: true, disabled: this.saving });
    broadcasterControl = new FormControl<number>({ value: null, disabled: true }, [Validators.required]); // contain BX.id
    altChannelControl = new FormControl<number>({ value: null, disabled: this.saving }, []);

    form = new FormGroup({
        name: this.nameControl,
        tags: this.tagsControl,
        disable: this.disableControl,
        mute: this.muteControl,
        alertingProfile: this.alertingProfileControl,
        cluster: this.clusterControl,
        broadcaster: this.broadcasterControl,
        failoverSource: this.failoverSourceControl,
        priorityUpgrade: this.priorityUpgradeControl,
        mergeMode: this.mergeModeControl,
        latency: this.latencyControl,
        pidProfileId: this.pidProfileControl,
        content_analysis: this.contentAnalysisControl,
        altChannelId: this.altChannelControl
    });
    showMoreOptions = false;
    loading = true;
    sourcesLoading = true;
    isEdit = false;
    isClone = false;
    isInvalidSubmit: boolean;
    exitUrl = Constants.urls.channels;
    isSubmitted = false;
    constants = Constants;
    maxFailoverSources = 9;

    clusterDNSPrefix: string;

    profiles: AlertingProfile[];
    profilesNames: string[];
    private profilesSubscription: Subscription;
    private sourcesSubscription: Subscription;

    action = "create";
    channelId: number;
    channelName: string;
    channelCluster: string;
    failoverChannel: FailoverChannel;
    existingChannel: FailoverChannel;
    sources: Source[];
    p1Group = createDefaultRuleControl(this.saving);
    frozenVideoGroup = createDefaultRuleControl(this.saving);
    blancPicGroup = createDefaultRuleControl(this.saving);
    silentAudioGroup = createDefaultRuleControl(this.saving);
    lowBitrateGroup = createDefaultRuleControl(this.saving, true);
    mappedGroups = [
        [this.p1Group, "p1_transport"],
        [this.frozenVideoGroup, "frozen_video"],
        [this.blancPicGroup, "blank_picture"],
        [this.silentAudioGroup, "silent_audio"],
        [this.lowBitrateGroup, "low_bitrate"]
    ] as const;

    constructor(
        private clusterService: ClustersService,
        private cs: ChannelsService,
        private sharedService: SharedService,
        private modalService: ModalService,
        private router: Router,
        private mixpanelService: MixpanelService,
        private route: ActivatedRoute,
        private ems: EventsManagementService,
        private ss: SourcesService,
        private titleService: TitleService,
        private translate: TranslateService,
        private titlecasePipe: TitleCasePipe,
        private pidMappingService: PidMappingsService
    ) {
        this.route.paramMap.subscribe(async params => {
            this.channelId = urlBuilder.decode(params.get("channelId"));
            this.channelName = params.get("name");
            this.channelCluster = params.get("cluster");
            this.action = this.router.url.split("/")[5];
            this.isEdit = this.action === "edit";
            this.isClone = this.action === "clone";

            if (this.channelName && this.channelId) {
                this.failoverChannel = Object.assign({}, this.cs.getCachedFailoverChannel(this.channelId));
                this.existingChannel = _.cloneDeep(this.failoverChannel);

                // Check if channel found in cache, if not get channels and channel
                if (this.sharedService.isEmptyObject(this.failoverChannel) || !this.failoverChannel.hasFullDetails) {
                    await this.cs.getFailoverChannels().toPromise();
                    this.failoverChannel = Object.assign({}, this.cs.getCachedFailoverChannel(this.channelId));

                    const channel = await this.cs.getFailoverChannel(this.failoverChannel.id);
                    if (channel) this.failoverChannel = Object.assign({}, channel);

                    this.existingChannel = _.cloneDeep(this.failoverChannel);
                    this.loading = false;
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }

            this.prepForm();
        });
    }

    ngOnInit() {
        this.loading = true;
        this.pidMappingService.refreshPIDMappingProfiles();
        this.ems.refreshAlertingProfiles();

        this.profilesSubscription = this.ems.alertingProfiles.subscribe((profiles: AlertingProfile[]) => {
            this.profiles = [...profiles];
            this.profilesNames = this.profiles.map(p => p.name);
            this.loading = false;
        });

        this.sourcesLoading = true;
        this.ss.refreshSources(true);
        this.sourcesSubscription = this.ss.sources.subscribe((sources: Source[]) => {
            this.sources = sources;
            this.sourcesLoading = false;
            this.prepForm();
        });

        this.prepForm();
    }

    ngOnDestroy() {
        this.profilesSubscription.unsubscribe();
        this.sourcesSubscription.unsubscribe();
    }

    prepForm() {
        if (this.failoverChannel) {
            if (!this.isClone) this.nameControl.setValue(this.failoverChannel.name);
            this.tagsControl.setValue(this.failoverChannel.resourceTags);
            this.disableControl.setValue(false);
            this.muteControl.setValue(this.failoverChannel.muted);
            this.alertingProfileControl.setValue(
                this.profilesNames?.find(profileName => profileName === this.failoverChannel?.alertingProfile?.name)
            );
            this.advanceData = {
                billing_code: this.failoverChannel.billing_code,
                billing_password: this.failoverChannel.billing_password,
                external_id: this.failoverChannel.external_id
            };
            this.errorConcealmentData = {
                error_concealment: this.failoverChannel.failoverSource?.error_concealment,
                error_concealment_continuous_timeline:
                    this.failoverChannel.failoverSource?.error_concealment_continuous_timeline,
                error_concealment_replace_frames: this.failoverChannel.failoverSource?.error_concealment_replace_frames,
                error_concealment_delay_ms: this.failoverChannel.failoverSource?.error_concealment_delay_ms,
                error_concealment_cbr_padding_kbps:
                    this.failoverChannel.failoverSource?.error_concealment_cbr_padding_kbps,
                error_concealment_cbr_padding_pcr_interval_ms:
                    this.failoverChannel.failoverSource?.error_concealment_cbr_padding_pcr_interval_ms,
                error_concealment_fix_cc: this.failoverChannel.failoverSource?.error_concealment_fix_cc
            };
            this.pidProfileControl.setValue(this.failoverChannel?.failoverSource?.pass_through_pid_map_profile_id);
            if (this.failoverChannel.processingCluster)
                this.clusterControl.setValue(this.failoverChannel.processingCluster.id);
            if (this.failoverChannel.deliveryChannel && this.broadcasterControl.value === null)
                this.broadcasterControl.setValue(this.failoverChannel?.deliveryChannel?.target_broadcaster_id ?? 0);
            if (this.sources)
                this.failoverSourceControl.setValue(
                    this.failoverChannel?.failoverSource?.failoverSources
                        .map(src => {
                            const source = this.sources.find(s => s.id === src.source_id);
                            return {
                                source,
                                min_bitrate: Math.floor(src.min_bitrate / 1000),
                                source_id: src.source_id,
                                priority: src.priority
                            };
                        })
                        .filter(fs => fs.source)
                );
            if (this.failoverChannel.failoverSource) {
                this.priorityUpgradeControl.setValue(
                    this.failoverChannel.failoverSource.allow_failover_priority_upgrade
                );
                this.mergeModeControl.setValue(this.failoverChannel.failoverSource.merge_mode);
                this.latencyControl.setValue(this.failoverChannel.failoverSource.latency);
                this.updateGroups(this.failoverChannel.failoverSource.failoverRules);
                this.contentAnalysisControl.setValue(this.failoverChannel.failoverSource.content_analysis);
            }
            if (this.failoverChannel.alt_channel_id) {
                this.altChannelControl.setValue(this.failoverChannel.alt_channel_id);
            }
        } else {
            this.alertingProfileControl.setValue(this.profilesNames?.find(profileName => profileName === "Default"));
        }

        // Set Title
        this.titleService.setTitle(
            this.translate.instant("CHANNEL") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.failoverChannel && this.failoverChannel.name ? this.failoverChannel.name : "")
        );
    }

    async onSubmit() {
        this.saving = true;
        const formControls = this.form.controls;
        this.isInvalidSubmit = false;

        const slateSources = this.failoverSourceControl.value.filter(src => src.priority === 0);
        if (slateSources.length > 1) {
            this.isInvalidSubmit = true;
            this.saving = false;
            return;
        }

        for (const [group, _] of this.mappedGroups) {
            if (group.enabledControl.errors && group.enabledControl.value) {
                this.isInvalidSubmit = true;
                this.saving = false;
                return;
            }

            for (const control in group.controls) {
                if (group.controls[control].control.errors && group.enabledControl.value) {
                    this.isInvalidSubmit = true;
                    this.saving = false;
                    return;
                }
            }
        }

        this.getClusterDNSPrefix(this.clusterControl.value);

        const model = {
            name: formControls.name.value,
            resource_tag_ids: formControls.tags.value.map(v => v.id),
            muted: this.muteControl.value,
            alerting_profile_id: this.profiles.find(profile => profile.name === this.alertingProfileControl.value).id,
            broadcaster_cluster_id: this.clusterControl.value,
            target_broadcaster_id: formControls.broadcaster.value,
            slate_source_id: slateSources[0]?.source.id,
            failover_sources: this.failoverSourceControl.value.map(source => {
                return {
                    source_id: source.source.id,
                    priority: source.priority,
                    min_bitrate: source.min_bitrate * 1000
                };
            }),
            is_enabled: this.isEdit ? this.failoverChannel.is_enabled : !this.disableControl.value,
            ...this.advanceData,
            ...this.errorConcealmentData,
            allow_failover_priority_upgrade: this.priorityUpgradeControl.value,
            merge_mode: this.mergeModeControl.value,
            latency: this.latencyControl.value,
            failoverRules: this.getGroupData(),
            pass_through_pid_map_profile_id: this.pidProfileControl.value || null,
            content_analysis: this.contentAnalysisControl.value,
            alt_channel_id: this.altChannelControl.value
        };

        let result: false | FailoverChannel;
        if (this.isEdit) {
            const mapping = {
                broadcaster_cluster_id: { objectsKey: "deliveryChannel", valuePath: "broadcaster_cluster_id" },
                target_broadcaster_id: { objectsKey: "deliveryChannel", valuePath: "target_broadcaster_id" },
                allow_failover_priority_upgrade: {
                    objectsKey: "failoverSource",
                    valuePath: "allow_failover_priority_upgrade"
                },
                content_analysis: { objectsKey: "failoverSource", valuePath: "content_analysis" },
                latency: { objectsKey: "failoverSource", valuePath: "latency" },
                merge_mode: { objectsKey: "failoverSource", valuePath: "merge_mode" },
                pass_through_pid_map_profile_id: {
                    objectsKey: "failoverSource",
                    valuePath: "pass_through_pid_map_profile_id"
                },
                failoverRules: { objectsKey: "failoverSource", valuePath: "failoverRules" }
            };

            const changedData = this.sharedService.getZixiObjDiff(model, this.existingChannel, [], mapping);

            const newSources = this.compareSources(this.existingChannel, model);
            if (!newSources) {
                delete changedData["failover_sources"];
            } else {
                Object.assign(changedData, { failover_sources: newSources });
            }

            const isEmptyData = this.sharedService.isEmptyObject(changedData);
            if (!isEmptyData) {
                const updatedChannel = await this.cs.updateChannel(this.failoverChannel, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedChannel;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "CHANNEL",
                        async () => {
                            const updateAndRestartChannel = await this.cs.updateChannel(this.failoverChannel, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartChannel) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart failover channel", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(
                                    urlBuilder.getRegularChannelUrl(
                                        this.failoverChannel.id,
                                        Constants.urls.channelTypes.failover,
                                        model.name
                                    )
                                );
                            } else this.saving = false;
                        },
                        model.name
                    );
                    this.saving = false;
                } else if (updatedChannel) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update failover channel", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(
                        urlBuilder.getRegularChannelUrl(
                            this.failoverChannel.id,
                            Constants.urls.channelTypes.failover,
                            model.name
                        )
                    );
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(
                        this.failoverChannel.id,
                        Constants.urls.channelTypes.failover,
                        model.name
                    )
                );
            }
        } else {
            result = (await this.cs.addChannel(model, "failover")) as FailoverChannel;
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create failover channel");
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(result.id, Constants.urls.channelTypes.failover, model.name)
                );
            } else this.saving = false;
        }

        if (result) {
            this.mixpanelService.sendEvent(`${this.action} failover channel`);
            this.saving = false;
            this.router.navigate([this.exitUrl]);
        }
        this.saving = false;
    }

    compareSources(existingChannel: FailoverChannel, model) {
        const reducedOriginal = _.map(existingChannel.failoverSource.failoverSources, fs => {
            return _.pick(fs, Object.keys(model.failover_sources[0]));
        });
        if (_.isEqual(reducedOriginal, model.failover_sources)) {
            return false;
        } else {
            return model.failover_sources;
        }
    }

    getClusterDNSPrefix(id: number) {
        const cluster = this.clusterService.getCachedCluster(null, id);
        if (cluster) this.clusterDNSPrefix = cluster.dns_prefix;
    }

    private updateGroups(rules: FailoverRule[]) {
        if (!rules) return;
        for (const [group, ruleName] of this.mappedGroups) {
            const rule = rules.find(r => r.rule === ruleName);
            if (!rule) continue;
            group.enabledControl.setValue(true);
            group.controls.sample_duration.control.setValue(rule.sample_duration);
            group.controls.samples.control.setValue(rule.samples);
            group.controls.pid.control.setValue(rule.pid);
            group.controls.samples_threshold.control.setValue(rule.samples_threshold);
            if (group.controls.min_bitrate) group.controls.min_bitrate.control.setValue(rule.min_bitrate);
        }
    }

    private getGroupData(): FailoverRule[] {
        return this.mappedGroups
            .filter(([group]) => group.enabledControl.value)
            .map(([group, ruleName]) => ({
                rule: ruleName,
                sample_duration: group.controls.sample_duration.control.value,
                samples: group.controls.samples.control.value,
                pid: group.controls.pid.control.value || null,
                samples_threshold: group.controls.samples_threshold.control.value,
                min_bitrate: group.controls.min_bitrate?.control.value || null
            }));
    }

    alternativeChannelFilter(channel: DeliveryChannel) {
        return !!channel.failover_channel_id;
    }
}
