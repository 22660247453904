<!--Adaptive -->
<div>
    <ng-container *ngIf="channel.adaptive && channel as AdaptiveChannel">
        <!-- 1 -->
        <div class="row g-0" *ngIf="!channel.is_transcoding">
            <div class="col-12 col-sm-6" *ngFor="let bitrate of channel.bitrates">
                <app-source-thumbnail
                    *ngIf="bitrate.source"
                    [source]="bitrate.source"
                    [info]="true"
                    [overlay]="true"
                    [grid]="false"
                    [bordered]="true"
                    [allowLivePlay]="true"
                ></app-source-thumbnail>
            </div>
        </div>
        <!-- 2 -->
        <div class="row g-0" *ngIf="channel.bitrates.length > 0 && channel.is_transcoding">
            <div class="col-12 col-sm-6">
                <h5 class="mb-1" *ngIf="channel.slateSource">{{ "PRIMARY" | translate }}</h5>
                <app-source-thumbnail
                    [source]="channel.bitrates[0].source"
                    [info]="true"
                    [overlay]="true"
                    [grid]="false"
                    [bordered]="true"
                    [allowLivePlay]="true"
                ></app-source-thumbnail>
            </div>
            <div class="col-12 col-sm-6" *ngIf="channel.slateSource">
                <h5 class="mb-1">{{ "SLATE" | translate }}</h5>
                <app-source-thumbnail
                    [source]="channel.slateSource"
                    [info]="true"
                    [overlay]="true"
                    [grid]="false"
                    [bordered]="true"
                    [allowLivePlay]="true"
                ></app-source-thumbnail>
            </div>
        </div>
    </ng-container>
    <!-- Delivery -->
    <ng-container *ngIf="channel.delivery && channel as DeliveryChannel">
        <div class="row g-0">
            <div class="col-12 col-sm-6" *ngFor="let src of channel.sources">
                <h5 class="mb-1" *ngIf="src.primary">{{ "PRIMARY" | translate }}</h5>
                <h5 class="mb-1" *ngIf="!src.primary">{{ "BACKUP" | translate }}</h5>
                <app-source-thumbnail
                    [source]="src.source"
                    [info]="true"
                    [overlay]="true"
                    [grid]="false"
                    [bordered]="true"
                    [allowLivePlay]="true"
                ></app-source-thumbnail>
            </div>
        </div>
    </ng-container>
    <!-- MediaConnect Flow -->
    <ng-container *ngIf="!channel.delivery && !channel.adaptive && channel as MediaConnectFlow">
        <div class="row g-0">
            <div class="col-12 col-sm-6">
                <zx-mediaconnect-source
                    [model]="channel.source"
                    [showStatusIcon]="true"
                    [showStatusText]="false"
                    [showLink]="true"
                    [showTag]="false"
                ></zx-mediaconnect-source>
            </div>
        </div>
    </ng-container>
</div>
