import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";
import { MatMenuModule } from "@angular/material/menu";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { ChannelFormComponent } from "./channel-form/channel-form.component";
import { ChannelFormAdaptiveComponent } from "./channel-form-adaptive/channel-form.component";
import { ChannelFormTranscodeComponent } from "./channel-form-transcode/channel-form.component";
import { ChannelFormPassThroughComponent } from "./channel-form-pass-through/channel-form.component";
import { ChannelFormMediaConnectFlowComponent } from "./channel-form-mediaconnect-flow/channel-form.component";
import { ChannelListComponent } from "./channel-list/channel-list.component";
import { ChannelComponent } from "./channel/channel.component";
import { ChannelTargetsComponent } from "./channel/channel-targets/channel-targets.component";
import { ChannelBitratesComponent } from "./channel/channel-bitrates/channel-bitrates.component";
import { ChannelAdMarkComponent } from "./channel/channel-ad-marks/channel-ad-marks.component";
import { ChannelFormMediaLiveChannelComponent } from "./channel-form-medialive-channel/channel-form.component";
import { ChannelFormFailoverChannelComponent } from "./channel-form-failover-channel/channel-form.component";

// Routes
import { ChannelsRouting } from "./channels.routing";
import { ChannelSourcesComponent } from "./channel/channel-sources/channel-sources.component";
import { ChannelAwsFieldsComponent } from "./channel-aws-fields/channel-aws-fields.component";
import { AllChannelSourcesComponent } from "./channel/all-channel-sources/all-channel-sources.component";
import { ChannelStreamComponent } from "./channel/channel-stream/channel-stream.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        MatMenuModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        ChannelsRouting
    ],
    declarations: [
        ChannelFormComponent,
        ChannelFormAdaptiveComponent,
        ChannelFormTranscodeComponent,
        ChannelFormPassThroughComponent,
        ChannelFormMediaConnectFlowComponent,
        ChannelFormMediaLiveChannelComponent,
        ChannelFormFailoverChannelComponent,
        ChannelListComponent,
        ChannelComponent,
        ChannelTargetsComponent,
        ChannelBitratesComponent,
        ChannelAdMarkComponent,
        ChannelStreamComponent,
        ChannelSourcesComponent,
        AllChannelSourcesComponent,
        ChannelAwsFieldsComponent
    ],
    providers: []
})
export class ChannelsModule {}
