<content-loading-animation *ngIf="loading"></content-loading-animation>
<app-form-wrapper [formGroup]="form" *ngIf="!loading" [titleMsg]="'FAILOVER_CHANNEL' | translate" [isEdit]="isEdit" [isClone]="isClone">
    <section id="info">
        <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>

        <app-input-generic-field
            [formControl]="nameControl"
            [textFieldName]="'NAME' | translate"
            [isParentFormSubmitted]="isSubmitted"
            [disabled]="isEdit"
            type="text"
        ></app-input-generic-field>

        <zx-access-tags-select
            [formControl]="tagsControl"
            [label]="'ACCESS_TAGS' | translate"
            name="tags"
            [isParentFormSubmitted]="isSubmitted"
        ></zx-access-tags-select>

        <app-zx-string-selection
            [formControl]="alertingProfileControl"
            [selectionOptions]="profilesNames"
            [label]="'ALERTING_PROFILE' | translate"
            [isParentFormSubmitted]="isSubmitted"
        ></app-zx-string-selection>
    </section>

    <section id="info">
        <app-form-section-title [title]="'INPUT' | translate"></app-form-section-title>

        <app-cluster-field-selection [formControl]="clusterControl" [isParentFormSubmitted]="isSubmitted"></app-cluster-field-selection>

        <app-broadcaster-field-selection
            [formControl]="broadcasterControl"
            [clusterId]="clusterControl.value"
            [isFormSubmitted]="isSubmitted"
            [isRestartRequiredTip]="isEdit"
        ></app-broadcaster-field-selection>

        <!-- Alternative Channel -->
        <div class="form-group">
            <label for="alternative_channel_select" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alternative_channel_select?.errors }"
                >{{ "ALTERNATIVE_CHANNEL" | translate }} ({{ "DISASTER_RECOVERY" | translate }})</label
            >
            <zx-failover-channel-select
                id="alternative_channel_select"
                name="alternative_channel_select"
                [(model)]="altChannelControl.value"
                [clearable]="true"
            >
            </zx-failover-channel-select>
        </div>

        <app-source-field-selection
            [formControl]="failoverSourceControl"
            [isParentFormSubmitted]="isSubmitted"
            [maxSourceSelect]="maxFailoverSources"
        ></app-source-field-selection>

        <!-- PID Mapping Profile -->
        <app-select-generic-field
            elementId="pid-mapping"
            title="{{ 'PID_MAPPING_PROFILE' | translate }}"
            valueToBind="id"
            [formControl]="pidProfileControl"
            placeHolder="{{ 'SELECT' | translate }} {{ 'PID_MAPPING_PROFILE' | translate }}"
            [isParentFormSubmitted]="isSubmitted"
            [isRestartRequiredTip]="isEdit"
            [items]="pidMappingProfiles$ | async"
            [clearable]="true"
        >
        </app-select-generic-field>
    </section>

    <!-- State -->
    <section id="state" *ngIf="!isEdit">
        <app-form-section-title [title]="'INITIAL_STATE' | translate"></app-form-section-title>

        <app-checkbox-field [formControl]="disableControl" [label]="'DISABLED' | translate" [id]="'DISABLED'"></app-checkbox-field>

        <app-checkbox-field [formControl]="muteControl" [label]="'MUTED' | translate" [id]="mute"></app-checkbox-field>
    </section>
    <!-- SOURCE -->
    <section id="source">
        <app-form-section-title [title]="'SOURCE' | translate"></app-form-section-title>
        <!-- Failover priority upgrade mode -->
        <app-checkbox-field
            [formControl]="priorityUpgradeControl"
            [label]="'HITLESS_PRIORITY_UPGRADE' | translate"
            [id]="'HITLESS_PRIORITY_UPGRADE'"
            [underLabel]="'HITLESS_PRIORITY_UPGRADE_NOTE' | translate"
        ></app-checkbox-field>

        <!-- Merge Mode -->
        <fieldset class="form-group">
            <legend for="input-merge-mode">
                {{ "FAILOVER_MERGE_MODE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
            </legend>
            <mat-button-toggle-group name="input-merge-mode" aria-label="input type mode" [formControl]="mergeModeControl">
                <mat-button-toggle value="none">{{ "NON_HITLESS" | translate }}</mat-button-toggle>
                <ng-container *ngIf="showMoreOptions">
                    <mat-button-toggle value="rtp">{{ "SMPTE_2022-7_HITLESS" | translate }}</mat-button-toggle>
                    <mat-button-toggle value="content">{{ "CONTENT_HITLESS" | translate }}</mat-button-toggle>
                </ng-container>
            </mat-button-toggle-group>
            <div class="more-option-btn" (click)="showMoreOptions = !showMoreOptions">
                {{ showMoreOptions ? ("HIDE_OPTIONS" | translate) : ("MORE_OPTIONS" | translate) }}
            </div>
            <br />
            <ng-container *ngIf="showMoreOptions">
                <small [innerHTML]="'FAILOVER_MERGE_MODES' | translate"></small>
                <br />
                <small [innerHTML]="'FAILOVER_MERGE_MODE_14.1' | translate"></small>
                <br />
            </ng-container>
        </fieldset>
        <!-- Search Window -->
        <app-input-generic-field
            [formControl]="latencyControl"
            [isParentFormSubmitted]="isSubmitted"
            [isRestartRequiredTip]="true"
            textFieldName="{{ (mergeModeControl.value !== 'none' ? 'MERGE_SEARCH_WINDOW' : 'OFFLINE_FAILOVER_WINDOW') | translate }} [ms]"
            placeHolder="{{ (mergeModeControl.value !== 'none' ? 'MERGE_SEARCH_WINDOW' : 'OFFLINE_FAILOVER_WINDOW') | translate }} [ms]"
            type="number"
        ></app-input-generic-field>

        <!-- Failover Rules -->
        <div class="form-group">
            <label for="failover_rules"
                >{{ "FAILOVER_NOTES.RULES" | translate
                }}<fa-icon icon="info-circle" [ngbTooltip]="failoverRulesContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                <ng-template #failoverRulesContent><span [innerHTML]="constants.failoverRulesHTML"></span></ng-template
            ></label>
            <!-- P1 Transport Errors -->
            <app-failover-rules [rules]="p1Group" [label]="'FAILOVER_NOTES.P1_TRANSPORT_FAILOVER' | translate" [isSubmitted]="isSubmitted"></app-failover-rules>
            <!-- Frozen Video Errors -->
            <app-failover-rules
                [rules]="frozenVideoGroup"
                [label]="'FAILOVER_NOTES.FROZEN_VIDEO_FAILOVER' | translate"
                [isSubmitted]="isSubmitted"
                [hasInfoCircle]="'FAILOVER_NOTES.FROZEN_VIDEO_CONTENT' | translate"
            ></app-failover-rules>
            <!-- Blank Picture Errors -->
            <app-failover-rules
                [rules]="blancPicGroup"
                [label]="'FAILOVER_NOTES.BLANK_PICTURE_FAILOVER' | translate"
                [isSubmitted]="isSubmitted"
                [hasInfoCircle]="'FAILOVER_NOTES.BLANK_PICTURE_CONTENT' | translate"
            ></app-failover-rules>
            <!-- Silent Audio Errors -->
            <app-failover-rules
                [rules]="silentAudioGroup"
                [label]="'FAILOVER_NOTES.SILENT_AUDIO_FAILOVER' | translate"
                [isSubmitted]="isSubmitted"
                [hasInfoCircle]="'FAILOVER_NOTES.SILENT_AUDIO_CONTENT' | translate"
            ></app-failover-rules>
            <!-- PID based Low bitrate Errors -->
            <app-failover-rules
                [rules]="lowBitrateGroup"
                [label]="'FAILOVER_NOTES.LOW_BITRATE_FAILOVER' | translate"
                [isSubmitted]="isSubmitted"
            ></app-failover-rules>
            <small [innerHTML]="'FAILOVER_NOTES.HITLESS_RULES_NOTE' | translate"></small>
            <br />
            <small>{{ "FAILOVER_NOTES.MENTIONED_PID_MUST_BE_PRESENT" | translate }}</small>
            <br />
            <small>{{ "FAILOVER_NOTES.MIN_BX_VERSION_NOTE" | translate }}</small>
            <br />
            <small>{{ "FAILOVER_NOTES.PID_BASED_RULES_NEED_16_6" | translate }}</small>
        </div>

        <!-- Content Analysis-->
        <div class="form-group">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="content_analysis" name="content_analysis" [formControl]="contentAnalysisControl" />
                <label class="form-check-label" for="content_analysis"
                    >{{ "ENABLE_CONTENT_ANALYSIS" | translate
                    }}<fa-icon icon="info-circle" [ngbTooltip]="CAContent" triggers="hover click" [closeDelay]="500"></fa-icon
                    ><ng-template #CAContent
                        ><p class="mb-0">
                            {{ "TOOLTIP.SOURCE_CONTENT_ANALYSIS" | translate }}
                        </p></ng-template
                    >
                </label>
            </div>
        </div>
    </section>

    <!-- Error Concealment -->
    <app-failover-error-concealment-form [model]="errorConcealmentData" [form]="form" [isEdit]="isEdit"></app-failover-error-concealment-form>

    <!-- Advanced -->
    <app-advanced-section-form [showBilling]="true" [showExternalID]="true" [model]="advanceData" [form]="form" [isEdit]="isEdit"></app-advanced-section-form>

    <app-error [marginBottom]="true" [marginTop]="false"></app-error>

    <app-zx-form-bottom-buttons
        [isFormValid]="form.valid"
        [(submitted)]="isSubmitted"
        (submit)="onSubmit()"
        [cancelUrlNavigate]="exitUrl"
        [isSaving]="saving"
        [isEdit]="isEdit"
    ></app-zx-form-bottom-buttons>
</app-form-wrapper>
