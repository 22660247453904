import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { ChannelsService } from "../channels.service";
import { DeliveryChannel } from "../channel";

@Injectable({
    providedIn: "root"
})
export class DeliveryChannelListResolverService implements Resolve<DeliveryChannel[]> {
    constructor(private cs: ChannelsService) {}

    resolve(): Observable<DeliveryChannel[]> | Observable<never> {
        return new Observable((observe: Subscriber<DeliveryChannel[]>) => {
            this.cs.getDeliveryChannels().subscribe((channels: DeliveryChannel[]) => {
                observe.next(channels);
                observe.complete();
            });
        });
    }
}
