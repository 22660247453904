<app-table-list
    [tableName]="'channel-targets'"
    [displayTableName]="'TARGETS' | translate"
    [data]="targets$ | async"
    [(tableSchema)]="tableColumnsSchema"
    [showSelectionCheckbox]="false"
    (currentSortDirection)="onSort($event)"
    [showReport]="false"
    [refreshing]="loadingTargets"
    [showPagination]="true"
    [showFilter]="false"
    [showColumnsSelection]="false"
    [selectable]="false"
    [hoverable]="false"
    [autoRows]="autoRows"
>
    <!-- Additional Buttons -->
    <div class="form-group mb-2" [ngClass]="{ 'ms-3 mt-2': bordered }">
        <button
            type="button"
            class="btn btn-outline-primary"
            (click)="assignTarget()"
            [disabled]="!canEdit"
            title="{{ 'ASSIGN_TARGET' | translate }}"
            *ngIf="!(channel && channel.mediaconnect && channel.mediaStreams && channel.mediaStreams.length > 0)"
        >
            <fa-icon icon="link" size="sm"></fa-icon>
            <span class="d-none d-lg-inline-block ms-1">{{ "ASSIGN_TARGET" | translate }}</span>
        </button>
        <button type="button" class="btn btn-outline-primary ms-2" (click)="addTarget()" title="{{ 'ADD_TARGET' | translate }}" [disabled]="!canEdit">
            <fa-icon icon="plus" size="sm"></fa-icon>
            <span class="d-none d-lg-inline-block ms-1">{{ "ADD_TARGET" | translate }}</span>
        </button>
    </div>
</app-table-list>
